/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-12 13:47:03
 * @Module: 格调学院详情页
 */
 <template>
  <div class="collegedetial">
    <div class="collegedetial-t">
      <div class="collegedetial-title">
        <b v-if="catalog">{{catalog.title}}</b>
        <span v-if="detailData">共{{detailData.chapter_count}}节课</span>
      </div>
      <div class="collegedetial-content">
        <div class="collegedetial-content-l">
          <div class="collegedetial-content-video">
            <aliplayer ref="aliplayer" v-if="playAuth" v-loading="videoLoading" element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-spinner="el-icon-loading" element-loading-text="视频加载中" :autoplay="true" :encryptType="1" @error="error" height="586px" @pause="pause" @ended="ended" @play="ready" :vid="playAuth.video_id" :playauth="playAuth.play_auth" />
            <transition name="el-fade-in-linear">
              <div class="experience" v-show="experience">
                <div>本课程为专享内容，需购买观看</div>
                <div v-show="preview_time">试看已结束，继续观看请前去购买</div>
                <div class="hover" @click="to_confirmOrder" v-if="detailData">{{detailData.price}}金币 购买课程</div>
                <div>—支付后刷新页面观看—</div>
              </div>
            </transition>
            <div class="preview-toast" v-if="showPreviewToast&&preview_time">
              本课程为付费课程，试看{{preview_time}}秒
              <span style="color:#df2a29;margin-left:6px;" class="hover" @click="to_confirmOrder">立即购买</span>
              <span class="el-icon-close hover" style="margin-left:6px;" @click="clearPreviewToast=true" />
            </div>
            <div class="seekText">
              {{seekText}}
            </div>
          </div>
          <div class="collegedetial-content-input">
            <div class="collegedetial-content-input-btn" v-if="detailData">
              <!-- <a class="hover">评论</a> -->
              <a class="hover" :style="detailData.has_collect?'color:#df2a29':''" @click="collectClick">
                <span class="iconfont " :class="detailData.has_collect?'iconshoucanghou':'iconshoucang'" />
                <span style="margin-left: 6px;">收藏</span>
              </a>
              <a class="hover" :style="detailData.has_liked?'color:#df2a29':''" @click="likeClick">
                <span class="iconfont" :class="detailData.has_liked?'icondianzanhou':'icondianzan'" />
                <span style="margin-left: 6px;">点赞</span>
              </a>
              <a class="hover" @click="transpondDialogVisible=true">
                <span class="iconfont iconfenxiang" />
                <span style="margin-left: 6px;">分享</span>
              </a>
            </div>
            <div style="font-size: 16px;" v-if="detailData&&!detailData.user_can_watch" class="hover buyBtn" @click="to_confirmOrder">前去购买本课程 <span class="el-icon-arrow-right"></span></div>
            <!-- <div class="collegedetial-content-input-from">
              <input type="text"
                     placeholder="发个评论与大家互动一下">
              <div class="collegedetial-content-input-from-btn hover">发表</div>
            </div> -->
          </div>
        </div>
        <div class="collegedetial-content-r">
          <div class="collegedetial-content-r-t" @click="to_detail">
            <el-avatar shape="circle" v-if="detailData" :src="detailData.teacher_avatar" :size="56"></el-avatar>
            <div class="collegedetial-content-r-t-info">
              <div v-if="detailData">{{detailData.teacher_name||"未命名"}}</div>
              <div v-if="detailData">关注人数：{{detailData.teacher_follower_count}}</div>
            </div>
          </div>
          <div class="collegeMenu">
            <div class="collegeMenu-title">目录</div>
            <ul class="collegeMenu-list" v-if="detailData">
              <li class="collegeMenu-item hover" :style="catalog_id===item.catalog_id?'color:#fff':'color:#999'" v-for="(item,index) in detailData.catalogs" @click="$throttle(()=>switch_catalog(item),2000)" :key="index">
                <div class="collegeMenu-item-image">
                  <el-image class="collegeMenu-item-image hover" fit="cover" :src="item.cover" />
                </div>
                <div class="collegeMenu-item-content">
                  <p class="collegeMenu-item-content-t ellipsis2">{{item.title}}</p>
                  <p class="collegeMenu-item-content-b">
                    <span>第{{item.order}}节 <img v-show="catalog_id===item.catalog_id" src="@/static/homepage/Lark20210111-112445.gif" style="width:10px;height:10px;"></span>
                    <span v-show="item.is_preview">免费</span>
                    <span v-show="!item.is_preview&&item.preview_time">试看</span>
                    <span v-show="!item.is_published">即将更新</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="collegedetial-comment">
      <div class="collegedetial-comment-title">
        <div class="collegedetial-comment-title-item hover" v-for="item in activeList" @click="active=item.key" :key="item.key">
          <div>{{item.title}}</div>
          <div class="solit" v-show="item.key==active"></div>
        </div>
      </div>

      <!-- 简介 -->
      <div v-if="detailData&&active=='content'">

        <div v-html="detailData.description" style="margin:0 auto;" class="description">
        </div>
        <el-image style="width: 1240px;margin:0 auto;display:block;" :src="detailData.cover" fit="contain"></el-image>
      </div>
      <!-- 评论 -->
      <div class="commentBox">
        <comment :data="{uuid:detailData.moment_id}" model_type="moment" v-if="detailData&&active=='comment'" />
      </div>
    </div>
    <transpond-dialog :visible="transpondDialogVisible" v-if="detailData" :data="{
												type:5,
												uuid:detailData.moment_id,
												moment_content:{
													cover:detailData.cover,
													title:detailData.title,
													user_name:detailData.teacher_name
												}
											}" @close="transpond_dialog_change" />
  </div>
</template>
 <script>
import aliplayer from "@/components/aliplayer"
import comment from "@/views/homepage/detail/module/comment"
import transpondDialog from "@/views/homepage/module/transpondDialog"
let currentTime = null
export default {
  components: {
    aliplayer,
    comment,
    transpondDialog
  },
  data () {
    return {
      videoLoading: true,
      course_id: null,
      experience: false,  //  试看
      detailData: null,   // 详情数据
      catalog_id: null, //播放课程的id
      playAuth: null,
      activeList: [
        {
          title: '简介',
          key: 'content'
        },
        {
          title: '评论',
          key: 'comment'
        }
      ],
      active: "content",
      clearPreviewToast: false,
      seekText: "",
      transpondDialogVisible: false  // 转发模态框
    };
  },
  computed: {  //  计算属性
    catalog () {   // 当前播放的课程
      if (this.detailData)
        return this.detailData.catalogs.find(item => item.catalog_id == this.catalog_id)
      else
        return null
    },
    user_can_watch () {    // 是否为课程免费（课程是否购买）
      return this.detailData ? this.detailData.user_can_watch : false
    },
    is_preview () {  // 是否为章节免费
      if (this.catalog) {
        if (this.user_can_watch) {
          return true
        } else {
          return this.catalog.is_preview
        }
      } else {
        return false
      }
    },
    preview_time () {  //试看时间
      return this.catalog ? this.catalog.preview_time : 0
    },
    showPreviewToast () {   //  显示立即购买的提示
      return !this.user_can_watch && !this.is_preview && !this.clearPreviewToast && !this.experience
    },

  },
  mounted () {
    const { course_id } = this.$route.query
    this.course_id = course_id
    this._get_detail(course_id).then(({ catalogs }) => {
      // const catalog = user_can_watch ? catalogs.find(item => item.is_latest_watch == true) || catalogs[0] : catalogs[0]       //  这个是只有购买之后才会跳转最后看的课程
      const catalog = catalogs.find(item => item.is_latest_watch == true) || catalogs[0]    // 这个是有没有购买 都会跳转最后看的课程
      this._get_PlayAuth(catalog.catalog_id)
      this.catalog_id = catalog.catalog_id
    })
    document.documentElement.scrollTop = 0
  },
  beforeDestroy () {
    this.set_progress()
    // 离开刷新页面  清除aliplayer sdk 的缓存
    // window.location.reload();
  },
  methods: {
    error () {
      window.location.reload();
    },
    // 获取鉴权
    verify_token () {
      const token = "web" + "+" + this.$guid(8) + "+" + (Date.parse(new Date()) / 1000)
      console.log(token)
      return this.$getRsaCode(token)
    },
    //记录学习进度
    set_progress () {
      const { catalogs } = this.detailData
      if (!this.$refs.aliplayer) return;
      const progress = parseInt(this.$refs.aliplayer.getCurrentTime())
      // 本地修改播放进度
      this.detailData.catalogs[catalogs.findIndex(item => item.catalog_id == this.catalog_id)].last_watch = progress
      // 服务器同步播放进度
      this.$api.courseRecordWatchProgressApi({
        catalog_id: this.catalog.catalog_id,
        progress
      })
    },
    nextPlay () {   //播放下一集
      const { catalogs } = this.detailData
      const nextIndex = catalogs.findIndex(item => item.catalog_id == this.catalog_id) + 1
      if (nextIndex <= catalogs.length)
        this.switch_catalog({ catalog_id: catalogs[nextIndex].catalog_id })
    },
    ended () {   //视频结束
      console.log("end")
      if (this.user_can_watch)  //如果为试看课程，视频结束  弹出购买弹窗
        this.nextPlay()
      else
        this.experience = true
    },
    ready (aliplayer) {  // 视频加载完成
      console.log("视频加载完成", aliplayer)
      const { last_watch } = this.catalog
      if (this.user_can_watch || this.is_preview) {    //如果用户能看且试看时间不为0 加载完成 跳转
        this.seek({ aliplayer, last_watch })
      } else {
        this.canLook(aliplayer)
      }
    },
    seek ({ aliplayer, last_watch }) {
      const duration = aliplayer.getDuration()   //总时长
      if (last_watch / duration > 0.9) return  //如果记录时长超过总时长的90%  不跳转
      aliplayer.seek(last_watch)
      const time = this.$toHHmmss(last_watch * 1000)
      this.seekText = `已为您跳转记录进度 ${time}`
    },
    //  获取详情内容
    async _get_detail (course_id) {
      const { status, info: message, data } = await this.$api.courseDetailApi({ course_id })
      return new Promise((suc) => {
        if (status === 200) {
          this.detailData = data
          suc(data)
        }
        else {
          this.$message({
            message,
            type: 'error'
          });
        }
      })
    },
    async _get_PlayAuth (catalog_id) {   // 获取视频播放凭证
      this.videoLoading = true
      const { data, status } = await this.$api.coursePlayAuthApi({ catalog_id, verify_token: this.verify_token() })
      if (status === 200) {
        this.videoLoading = false
        this.playAuth = data
        return new Promise(suc => {
          suc(data)
        })
      } else {
        this.videoLoading = false
        this.$message.error("观看人数太多，请刷新后重试。")
      }
    },
    async switch_catalog ({ catalog_id, is_published }) {  // 切换目录
      if (!is_published && (typeof (is_published) !== undefined)) {
        this.$message.info("即将更新，敬请期待。")
        return
      }
      if (catalog_id === this.catalog_id) return
      this.$refs.aliplayer.pause();
      this.pause()
      this.clearCurrentTime()
      const { play_auth: playauth, video_id: vid } = await this._get_PlayAuth(catalog_id)
      console.log({ playauth, vid })
      this.$refs.aliplayer.replayByVidAndPlayAuth(vid, playauth);
      this.catalog_id = catalog_id
      this.experience = false   // 关闭购买弹出框
      this.set_progress()   //保存进度
    },
    //  跳转购买课程
    to_confirmOrder () {
      const { href } = this.$router.resolve({
        path: "/college/confirmOrder",
        query: {
          course_id: this.course_id,
          price: this.detailData.price,
          title: this.detailData.title,
          chapter_count: this.detailData.chapter_count,
          cover: this.detailData.cover
        }
      });
      window.open(href, '_blank');
    },
    // 课程点赞事件
    likeClick ({ back }) {
      const type = this.detailData.has_liked == 0 ? 1 : 0
      this.detailData.has_liked = type
      if (back) return   // back 请求失败回退
      this._get_like({ type })
    },
    // 课程收藏事件
    collectClick ({ back }) {
      const type = this.detailData.has_collect == 0 ? 1 : 0
      this.detailData.has_collect = type
      if (back) return  // back 请求失败回退
      this._get_collect({ type })
    },
    // 请求点赞
    async _get_like ({ type }) {
      const { moment_id } = this.detailData
      const { status, info } = await this.$api.momentLikeApi({ model_id: moment_id, type, model_type: "moment" })
      if (status == 200) {
        this.$message.success(info)
      } else {
        this.$message.error(info)
        this.likeClick({ back: true })
      }
    },
    // 请求收藏
    async _get_collect ({ type }) {
      const { moment_id } = this.detailData
      const { status, info } = await this.$api.momentCollectApi({ model_id: moment_id, type, model_type: "moment" })
      if (status == 200) {
        this.$message.success(info)
      } else {
        this.$message.error(info)
        this.collectClick({ back: true })
      }
    },
    // 转发弹出框回调
    transpond_dialog_change (res) {
      this.transpondDialogVisible = false
      if (res) {
        this.$message.success("转发成功")
      } else {

        this.$message.warning("取消转发")
      }
    },

    //暂停播放
    pause () {
      console.log("暂停播放")
      this.clearCurrentTime()
    },
    //  清空计时器
    clearCurrentTime () {
      currentTime && clearInterval(currentTime)
      currentTime = null
      console.log(currentTime)
    },
    canLook (aliplayer) {  //试看逻辑
      console.log("试看课程。监听课程进度。")
      console.log(currentTime)
      if (!currentTime)
        currentTime = setInterval(() => {
          let nowTime = aliplayer.getCurrentTime()
          console.log(nowTime)
          let previewTime = this.preview_time  //  试看时间
          if (previewTime < nowTime) {
            aliplayer.pause()
            aliplayer.seek(0)
            this.experience = true
          }
        }, 1000)
    },
    to_detail () {
      this.$store.dispatch("homepage/toFollowe", {
        uuid: this.detailData.teacher_id
      })
    }
  },
  watch: {
    seekText (seekText) {
      if (seekText)
        setTimeout(() => {
          this.seekText = ""
        }, 5000);
    }
  }
};
 </script>
 <style lang='scss' scoped>
.collegedetial {
  min-height: 100vh;
  &-t {
    background: #181818;
    padding-bottom: 26px;
  }
  &-title {
    line-height: 80px;
    color: #fff;
    width: 1240px;
    height: 80px;
    margin: 0 auto;
    b {
      font-size: 24px;
      font-weight: 500;
    }
    span {
      margin-left: 10px;
      font-size: 14px;
    }
  }
  &-content {
    width: 1240px;
    margin: 0 auto;
    height: 636px;
    display: flex;
    &-l {
      width: 960px;
      height: 100%;
    }
    &-r {
      width: 280px;
      height: 100%;
      background: #2d2d2d;
      &-t {
        padding: 0px 0px 16px 18px;
        display: flex;
        align-items: center;
        height: 90px;
        &-info {
          margin-left: 9px;
          :first-child {
            color: #ffffff;
            line-height: 22px;
            font-size: 16px;
          }
          :last-child {
            color: #999999;
            line-height: 20px;
            font-size: 14px;
          }
        }
      }
    }
    &-video {
      height: 586px;
      position: relative;
      .preview-toast {
        position: absolute;
        bottom: 2px;
        left: 10px;
        color: #fff;
        font-size: 14px;
      }
      .seekText {
        position: absolute;
        bottom: 60px;
        right: 10px;
        color: #fff;
        font-size: 14px;
        z-index: 12;
      }
      &:hover {
        .preview-toast {
          bottom: 60px;
        }
      }
    }
    &-input {
      height: 50px;
      background: #1e1e1e;
      display: flex;
      align-items: center;
      padding: 0 30px;
      justify-content: space-between;
      &-btn {
        a {
          margin-right: 30px;
          color: #999999;
        }
      }
      &-from {
        width: 330px;
        height: 30px;
        background: #262626;
        border-radius: 15px;
        display: flex;
        padding-left: 14px;
        input {
          flex: 1;
          margin-right: 14px;
          background-color: transparent;
          color: #fff;
          font-size: 12px;
        }
        &-btn {
          width: 50px;
          height: 30px;
          background: #666666;
          border-radius: 15px;
          color: #fff;
          line-height: 30px;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .description {
    width: 1240px;
    margin: 0 auto;
    padding: 30px 42px;
    background: #fff;
    & /deep/ img,
    p,
    span {
      width: 100%;
    }
    & /deep/ p {
      line-height: 22px;
    }
    padding-bottom: 50px;
  }
}
.experience {
  width: 960px;
  height: 586px;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  :nth-child(1) {
    color: #fff;
    font-size: 20px;
    margin-top: 205px;
    text-align: center;
  }
  :nth-child(2),
  :nth-child(4) {
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin-top: 14px;
    text-align: center;
    opacity: 0.5;
  }
  :nth-child(3) {
    width: 171px;
    height: 40px;
    background: #df2a29;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    margin: 0 auto;
    margin-top: 22px;
    font-size: 17px;
  }
  :nth-child(4) {
    margin-top: 32px;
  }
}
.collegeMenu {
  &-title {
    font-size: 16px;
    color: #999999;
    line-height: 22px;
    padding: 7px 12px;
  }
  &-item {
    color: #999;
    padding: 12px;
    display: flex;
    &-image {
      width: 116px;
      height: 67px;
      border-radius: 3px;
    }
    &-content {
      margin-left: 8px;
      &-t {
        width: 132px;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
      }
      &-b {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
  &-list {
    height: 508px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 3px;
      width: 5px;
    }
    .collegeMenu-item:last-child {
      margin-bottom: 50px;
    }
  }
}
.collegedetial-comment {
  &-title {
    line-height: 65px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    width: 1240px;
    border-bottom: 1px #eee solid;
    display: flex;
    &-item {
      width: 169px;
      height: 68px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .solit {
        width: 73px;
        height: 3px;
        background: #df2a29;
        border-radius: 2px;
      }
    }
  }
}
.commentBox {
  width: 1240px;
  margin: 0 auto;
  background: #fff;
  padding: 30px 42px;
  & /deep/ .comment-title {
    display: none;
    margin: 0px;
  }
  & /deep/ .comment-box {
    margin-top: 0px;
  }
  & /deep/ .comment-input {
    margin-top: 0px;
  }
}
.buyBtn {
  &:hover {
    color: #fff;
  }
}
</style>