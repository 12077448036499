/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-10 16:38:57
 * @Module: 评论模块
 */
 <template>
  <div class="comment-box"
       v-loading="loading">
    <div class="comment-title">
      <div>//</div>
      <div>评论</div>
    </div>
    <div class="comment-input">
      <Mentionable :keys="['@']"
                   :items="items"
                   insert-space
                   ref="at"
                   style="width:650px"
                   offset="6">
        <el-input type="textarea"
                  id="comment-input"
                  :autosize="{ minRows: 5 }"
                  max="1000"
                  resize="none"
                  style="min-height:112px;"
                  placeholder="请输入内容"
                  v-model="content">
        </el-input>
      </Mentionable>
      <div class="comment-input-b">
        <div class="comment-input-b-l">
          <div style="position:relative;">
            <el-avatar :size="30"
                       :src="userInfo ? userInfo.avatar : ''"></el-avatar>
            <img src="@/static/img/renzheng.gif"
                 alt=""
                 v-if="!(userInfo.user_verify == null)"
                 style="width:12px;height:12px;position:absolute;bottom:1px;right:4px;" />
          </div>
          <div class="comment-input-b-l-nickname">
            <span>{{ userInfo ? userInfo.nick_name : "未登录" }}</span>
          </div>
          <div class="comment-input-b-l-parent"
               v-if="parent_name">
            <span style="float:left;">回复：{{ parent_name }}</span>
            <i class="el-icon-close hover"
               style="float:right;line-height: 30px;"
               @click="clearReply"></i>
          </div>
        </div>
        <div class="comment-input-b-r">
          <emojis @getEmo="getEmo" />
          <div>{{ content.length }}/1000</div>
          <div class="hover"
               @click="_set_comment">提交评论</div>
        </div>
      </div>
    </div>
    <div class="comment-title">
      <div>//</div>
      <div>评论区（{{ pagination.total }}）</div>
    </div>
    <div class="comment-list-box">
      <div class="comment-list"
           v-for="item in list"
           :id="'COMENT' + item.comment_id"
           :key="item.comment_id">
        <div class="comment-list-avatar"
             style="position:relative;"
             @click="goInfo(item.user_id)">
          <el-avatar :size="40"
                     :src="item.user_avatar"></el-avatar>
          <img src="@/static/img/renzheng.gif"
               alt=""
               v-if="!(item.user_verify == null)"
               style="width:12px;height:12px;position:absolute;bottom:1px;right:4px;z-index:100" />
        </div>
        <div class="comment-list-content">
          <div class="comment-list-content-t">
            <div style="margin-bottom:4px">
              <span :class="item.user_is_vip ? 'new-color' : ''">{{
                item.user_name
              }}</span>
            </div>
            <span>{{ item.created_at }}</span>
          </div>
          <div class="comment-list-content-c">
            <atTextRender :text="item.content"
                          :data="item" />
          </div>
          <div class="comment-list-content-b">
            <span class="hover">
              <span @click="reply(item.comment_id,item.user_name,item.comment_id)"
                    v-if="item.type !=='like'">回复</span>
              <span v-show="childData[item.comment_id]"
                    @click="clearCommentChild(item.comment_id)">·收起回复</span>
              <span v-show="!childData[item.comment_id]&&item.descendants_count"
                    @click="_get_commentChild(item.comment_id)">·{{item.descendants_count}}条回复</span>
            </span>
            <i class="el-icon-arrow-down"
               v-show="item.descendants_count"></i>
          </div>
          <div v-if="childData[item.comment_id]">
            <div class="comment-list-content-child"
                 v-for="itm in childData[item.comment_id]"
                 :id="'COMENT' + itm.comment_id"
                 :key="itm.comment_id">
              <div class="comment-list-content-child-avatar">
                <el-avatar :size="40"
                           :src="itm.user_avatar"></el-avatar>
                <img src="@/static/img/renzheng.gif"
                     alt=""
                     v-if="itm.has_verify"
                     style="width:12px;height:12px;position:absolute;bottom:42px;right:4px;z-index:100" />
              </div>
              <div class="comment-list-content-child-content">
                <div class="comment-list-content-child-content-t"
                     style="margin-bottom:4px">
                  <span :class="itm.user_is_vip ? 'new-color' : ''">{{
                    itm.user_name
                  }}</span>
                  <span>{{ itm.created_at }}</span>
                </div>
                <div class="comment-list-content-child-content-c">
                  <span style="color:#406599"
                        v-if="itm.parent_user !== item.user_name">@{{ itm.parent_user }}：</span>
                  <atTextRender :text="itm.content"
                                :data="itm" />
                </div>
                <div class="comment-list-content-child-content-b">
                  <span class="hover"
                        v-if="itm.type !=='like'"
                        @click="
                      reply(itm.comment_id, itm.user_name, item.comment_id)
                    ">回复</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <no-data v-show="pagination.total==0&& !loading">暂无评论</no-data>
      <div class="comment-list-pagination"
           v-show="pagination.total>pagination.pageSize">
        <el-pagination layout="prev, pager, next"
                       style="margin:0 auto;"
                       hide-on-single-page
                       :current-page="pagination.current_page"
                       @current-change="page=>_get_list(page)"
                       :page-size="pagination.pageSize"
                       :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
 <script>
import userinfo from "@/mixins/userinfo"
import commentMixins from "@/mixins/commentMixins"
import noData from "@/components/noData"
import emojis from "@/components/emojis"
import emojiMixins from "@/components/emojis/emojiMixins"
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    noData,
    emojis
  },
  mixins: [userinfo, commentMixins, emojiMixins],
  data () {
    return {

    };
  },
  created () {
    this._get_list();
  },
  methods: {
    scroll (id) {
      document
        .querySelector(id)
        .scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }
};
</script>
 <style lang='scss' scoped>
.comment-box {
  margin-top: 40px;
  min-height: 300px;
}
.comment-title {
  margin-top: 40px;
  font-size: 18px;
  display: flex;
  div:first-child {
    color: #ec7f7f;
    font-weight: 600;
  }
  div:last-child {
    color: #333;
  }
}
.comment-input {
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  min-height: 162px;
  margin-top: 16px;
  & /deep/ .el-textarea__inner {
    border: none;
  }
  &-b {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    justify-content: space-between;
    background: #f9f9f9;
    &-l {
      display: flex;
      align-items: center;
      color: #df2a29;
      font-size: 14px;
      &-nickname {
        margin-left: 10px;
        margin-right: 30px;
      }
      &-parent {
        min-width: 218px;
        line-height: 30px;
        background: #f6eaea;
        border-radius: 2px;
        padding: 0 14px;
      }
    }
    &-r {
      display: flex;
      align-items: center;
      font-size: 14px;
      div:first-child {
        color: #999999;
      }
      div:last-child {
        width: 84px;
        line-height: 30px;
        background: #df2a29;
        border-radius: 2px;
        text-align: center;
        color: #fff;
        margin-left: 12px;
      }
    }
  }
}
.comment-list-box {
  margin-top: 16px;
}
.comment-list {
  display: flex;
  margin-bottom: 16px;
  &-avatar {
    width: 40px;
    height: 40px;
    position: relative;
  }
  &-content {
    min-height: 85px;
    margin-left: 10px;
    &-t {
      margin-top: 7px;
      color: #999999;
      font-size: 12px;
      span:first-child {
        color: #406599;
        font-size: 14px;
      }
    }
    &-c {
      margin-top: 12px;
      font-size: 14px;
      color: #333;
      line-height: 20px;
    }
    &-b {
      margin-top: 8px;
      font-size: 12px;
      color: #5071a5;
    }
    &-child {
      margin-top: 16px;
      display: flex;
      &-content {
        min-height: 85px;
        margin-left: 10px;
        &-t {
          margin-top: 7px;
          color: #999999;
          font-size: 12px;
          span:first-child {
            color: #406599;
            font-size: 14px;
          }
        }
        &-c {
          margin-top: 12px;
          font-size: 14px;
          color: #333;
          line-height: 20px;
        }
        &-b {
          margin-top: 8px;
          font-size: 12px;
          color: #5071a5;
        }
      }
    }
  }
  &-pagination {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.new-color {
  color: #df2a29 !important;
}
</style>